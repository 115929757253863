<template>
  <div>
    <PageLoader />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="black" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout justify-center wrap>
      <v-flex xs12 md12 px-md-3 px-lg-3 px-xl-3>
        <v-layout wrap>
          <v-flex xs6>
            <span class="homeTop">Members</span>
          </v-flex>
          <v-flex xs6 text-right>
            <v-dialog
              :retain-focus="false"
              v-model="dialog"
              persistent
              max-width="800px"
              :key="dialog"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined v-bind="attrs" v-on="on"> Add </v-btn>
              </template>
              <v-card>
                <v-form>
                  <v-card-title>
                    <span class="headline">Add Member</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-layout justify-center wrap>
                        <v-flex xs12>
                          <v-text-field
                            class="homeText"
                            outlined
                            dense
                            v-model="team.name"
                            label=" Name of the Team Member"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                            class="homeText"
                            outlined
                            dense
                            v-model="team.designation"
                            label="Designation of the Team Member"
                          ></v-text-field> </v-flex
                        ><v-flex xs12>
                          <span class="homeText">
                            Description of the Team Member</span
                          >
                          <div id="app">
                            <vue-editor v-model="team.description"></vue-editor>
                          </div>
                        </v-flex>
                        <v-flex md12 xs12 py-5>
                          <ImageComp
                            @stepper="winStepper"
                            :heading="'Upload Image'"
                            :componentType="'topimage'"
                          />
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialog = false">
                      Close
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="validateInput()">
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-start v-if="list.length > 0">
          <v-flex v-for="(item, i) in list" :key="i" md3 pa-4>
            <v-card style="line-height: 16px">
              <v-img
                style="width: 100%; object-fit: contain; cursor: pointer"
                :src="mediaURL + item.image"
                height="300px"
                cover
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="#FF2323"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-layout wrap justify-center px-2 py-4>
                <v-flex xs12>
                  <span class="homeName"> {{ item.name }} </span><br /><br />
                  <span
                    style="
                      font-size: 13px;
                      font-weight: bold;
                      color: #000000;
                      font-family: poppinsregular;
                    "
                  >
                    {{ item.designation }}
                  </span>
                  <span
                    class="showLine"
                    style="
                      font-size: 13px;
                      color: #000000;
                      font-family: poppinsregular;
                    "
                    v-html="item.description"
                  >
                  </span>
                </v-flex>
                <v-flex pt-5 xl6 lg6 md6 pb-2 sm6 xs6 px-5 justify-center>
                  <v-dialog v-model="item.delete" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        class="mr-2"
                        outlined
                        block
                        v-on="on"
                        v-bind="attrs"
                        color="red"
                      >
                        Delete
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title
                        >Are you sure you want to delete this
                        Image?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="item.delete = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteItem(item)"
                          >OK</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-flex>
                <v-flex pt-5 xl6 lg6 md6 pb-2 sm6 xs6 px-5 justify-center>
                  <v-btn
                    small
                    class="mr-2"
                    outlined
                    block
                    color="green"
                    @click="editSlider(item)"
                  >
                    Edit
                  </v-btn>
                </v-flex>
                <br />
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout v-else justify-center wrap pt-5>
          <v-flex xs12 text-center>
            <span class="homeText">No Data Found.....</span>
          </v-flex>
        </v-layout>
        <v-dialog v-model="editdialog" max-width="800px">
          <v-card>
            <v-card-title>
              <span class="headline">Edit Team</span>
            </v-card-title>
            <v-card-text>
              <v-layout justify-center wrap>
                <v-flex xs12>
                  <v-text-field
                    class="homeText"
                    outlined
                    dense
                    v-model="editingitem.name"
                    label="Name of the Team Member"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    class="homeText"
                    outlined
                    dense
                    v-model="editingitem.designation"
                    label="Designation of the Team Member"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <span class="homeText">Description of the Team Member</span>
                  <div id="app">
                    <vue-editor v-model="editingitem.description"></vue-editor>
                  </div>
                </v-flex>
                <v-flex md12 xs12 py-5>
                  <ImageComp
                    @stepper="winStepper"
                    :singleImage="editingitem.image"
                    :heading="'Upload Image'"
                    :componentType="'topimage'"
                  />
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="editdialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="edit(editingitem._id)">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import store from "./../../store";
import ImageComp from "@/components/Common/singleImage";
import { VueEditor } from "vue2-editor";
export default {
  components: {
    ImageComp,
    VueEditor,
  },
  data() {
    return {
      team: { designation: null, name: null, description: null },
      list: [],
      editingitem: [],
      dialog: false,
      editdialog: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      formData: new FormData(),
      selectedFiles: [],
      image: null,
      imagePreview: "",
      showPreview: false,
      file: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      id: null,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    winStepper(window_data) {
      if (window_data.type == "topimage") {
        this.topimageFile = window_data.selectedFiles;
      }
    },
    validateInput() {
      if (!this.team.designation) {
        this.msg = "Please Provide Designation of the Team";
        this.showSnackBar = true;
        return;
      } else if (!this.team.name) {
        this.msg = "Please Provide Name of the Team";
        this.showSnackBar = true;
        return;
      } else if (!this.team.description) {
        this.msg = "Please Provide Description of the Team";
        this.showSnackBar = true;
        return;
      } else {
        this.add();
      }
    },
    getList() {
      store.commit("appLoading", true);
      axios({
        method: "GET",
        url: "/team/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.list = response.data.data;
          store.commit("appLoading", false);
        })
        .catch((err) => {
          store.commit("appLoading", false);
          this.ServerError = true;
          console.log(err);
        });
    },
    add() {
      axios({
        method: "post",
        url: "/team/add",
        data: this.team,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.id = response.data.id;
            Object.keys(this.team).forEach((key) => (this.team[key] = null));
            if (this.topimageFile) {
              this.uploadImage(this.id);
            }
            this.msg = " Added Successully";
            this.showSnackBar = true;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    uploadImage(item) {
      this.formDataCover.append("image", this.topimageFile);
      this.formDataCover.append("id", item);
      axios({
        method: "POST",
        url: "/team/image",
        data: this.formDataCover,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          store.commit("appLoading", false);
          if (response.data.status == true) {
            this.msg = "Upload Successfully";
            this.showSnackBar = true;
            this.formDataCover = new FormData();
            this.dialog = false;
            this.editdialog = false;
            this.getList();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          store.commit("appLoading", false);
          this.msg = "Can't Upload Cover Image.. Please Try Again Later";
          this.showSnackBar = true;
          console.log(err);
        });
    },
    edit() {
      this.appLoading = true;
      var user = {};
      user["name"] = this.editingitem.name;
      user["designation"] = this.editingitem.designation;
      user["description"] = this.editingitem.description;
      user["id"] = this.editingitem;
      axios({
        method: "POST",
        url: "/team/edit",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: user,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.editdialog = false;
            this.getList();
            if (this.topimageFile) {
              this.uploadImage(this.editingitem._id);
            }
          } else {
            this.msg = "Can't Update";
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editSlider(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    deleteItem(r) {
      var data = {};
      data["id"] = r._id;
      axios({
        url: "/team/remove",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showSnackBar = true;
            this.getList();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
.icon {
  padding-left: 170px;
}
.showLine {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.v-application .primary {
  background-color: red !important;
}
.texttt {
  line-height: 1.5em;
  min-height: 6.5em;
  float: left;
}
</style>